<template>
  <div>
    <el-table
      :data='dataList'
      class='plc-tbl'
      border
      v-if='columns.length>0&&dataList.length>0'
    >
      <el-table-column
        v-for='(v,k) in columns'
        :key='k'
        :prop="typeof(v.field)=='string'?v.field:''"
        :label='v.title'
        :min-width='v.width'
        :show-overflow-tooltip='false'
        :header-align="v.header_align?v.header_align:'center'"
        :align="v.align?v.align:'center'"
        :sortable='v.sortable?v.sortable:false'>
        <template slot='header'>
          <div style='color: #f2f2f2'>{{ v.title }}</div>
        </template>
        <template slot-scope='{row}'>
          <div>
            <div v-if='v.field==="cover_pc_url"'>
              <el-avatar :size='80' :src='row[v.field]' @error='true' style='min-width: 80px'>
                <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' alt='头像' />
              </el-avatar>
            </div>
            <div v-else-if='["scs","sri","mfr"].indexOf(v.field)>-1'>
              <div v-if='row.real_quote.mixed' v-html='row.real_quote.mixed[`${v.field}`]["val"]'
                   style='height: 100%;width: 100%;text-align: left;font-size:12px;line-height: 12px'></div>
            </div>
            <div v-else-if='v.field.indexOf("quote_")>-1'>
              {{ showSingle(row?.real_quote?.single, v.field) }}
            </div>
            <div v-else-if='v.field ==="multi" && v.children'>
              <el-table v-if='v.children.length>0' :data='row?.real_quote?.multi' border :show-header='false'>
                <el-table-column
                  v-for='(v,k) in v.children'
                  :key='k'
                  :prop="typeof(v.field)=='string'?v.field:''"
                  :label='v.title'
                  :min-width='v.width'
                  :show-overflow-tooltip='false'
                  :header-align="v.header_align?v.header_align:'center'"
                  :align="v.align?v.align:'center'"
                  :sortable='v.sortable?v.sortable:false'>
                </el-table-column>
              </el-table>
            </div>
            <div v-else style='height: 100%;width: 100%;text-align: center;font-size:12px;line-height: 12px'>
              <div v-html='row[v.field]'></div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'TableRowView',
  props: {
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    dataList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    showSingle(rows, field) {
      if (rows) {
        // console.log(typeof rows)
        const obj = rows.find(value => value.field === field)
        return obj.val
      }
    }
  }
}
</script>

<style scoped>
::v-deep .plc-tbl th.el-table__cell {
  background-color: #fe346e !important;
  color: #f2f2f2;
  padding: 0 !important;
  line-height: 36px;
}

::v-deep .plc-tbl td.el-table__cell .cell {
  padding: 2px;
}

</style>
