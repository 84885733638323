<template>
  <div v-if='quoteInfo' style='padding: 10px'>
    <div class='flex-sb' style='margin-bottom: 10px'>
      <div>
        <span class='opt-title'>{{ title }}-报价明细</span>
        &nbsp;
        &nbsp;
        <el-button v-if='showReset' type='text' icon='el-icon-refresh' @click='handleReset'>重置</el-button>
      </div>
    </div>

    <div v-if='showMixedPrice' style='text-align:center;margin: auto'>
      <div v-if='quoteInfo.mixed' class='box-mixed'>
        <div class='price-mixed' v-for='(mixedItem,key) in quoteMixedStruct' :key='key'>
          <div>
            <span class='price-title'>{{ mixedItem.label || '' }}</span>
          </div>
          <div class='rich-txt'>
            <ApeEditor key='quoteInfoMixedSri' :toolbar='toolbar' :init-html='quoteInfo.mixed[mixedItem.field].val'
                       v-if='isRenderEditor'
                       :editor-height='340'
                       @handleTinymceInput='(e)=>handleLSFCRichTxt(e,mixedItem.field)'></ApeEditor>
          </div>
        </div>
      </div>
    </div>

    <div v-if='showMultiPrice' style='width: 870px;text-align: center;margin: auto;padding: 10px' class='border-effect'>
      <div style='margin-bottom: 5px' v-if='quoteInfo.multi'>
        <div class='flex-sb'>
          <div><span class='price-title'>{{ quoteMultiCols.label || '' }}</span></div>
          <el-button type='text' icon='el-icon-plus' @click='addMultiRow'
                     v-if='editPrice'>添加
          </el-button>
        </div>
        <el-table :data='quoteInfo.multi' v-if='quoteInfo&&quoteInfo.multi' border>
          <template v-for='(col,index) in quoteMultiCols.cols'>
            <el-table-column :label='col.title' :prop='col.field' align='center' :min-width='col.width||100'
                             :key='`${col.field}_${index}`'>
              <template slot-scope='{row,column}'>
                <div v-if='editPrice'>
                  <el-input :style='`width: ${input_width}`' v-if='col.type==="input"' v-model='row[column.property]'
                            :placeholder='col.placeholder' clearable></el-input>
                  <el-select :style='`width: ${input_width}`' v-else-if='col.type==="select"' filterable allow-create
                             v-model='row[column.property]'
                             :placeholder='col.placeholder' clearable>
                    <el-option v-for='(option,idx) in col.options'
                               :value='option.value' :label='option.label' :key='idx'></el-option>
                  </el-select>
                </div>
                <div v-else>
                  <span>{{ row[column.property] }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column label='操作' align='center' min-width='60'>
            <template slot-scope='{$index}'>
              <el-button type='text' icon='el-icon-close' @click='delMultiRow($index)'
                         :disabled='!editPrice'></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-if='showSinglePrice' class='border-effect'>
      <div v-if='quoteInfo.single'>
        <div v-for='(item,index) in quoteInfo.single' :key='index'>
          <div style='display: flex'>
            <span class='is-label' style='flex: 2;'>{{ item.title }}</span>
            <div style='flex: 3'>
              <el-input :placeholder="item.placeholder||'请输入当月报价'" v-model='item.val'
                        type='text' :disabled='!editPrice' clearable>
              </el-input>
            </div>
            <div style='flex: 5;margin-left: 5px'>
              <el-input placeholder='请输入备注信息' v-model='item.remark' :disabled='!editPrice' clearable
                        style='width: calc(100% - 30px);margin-right: 10px;'
              />
              <el-button type='text' icon='el-icon-close' v-if='editPrice'
                         @click='quoteInfo.single.splice(index,1)' />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span>数据结构异常，请联系管理员或者重置报价信息!!! (旧版数据兼容问题)</span>
      </div>
    </div>
  </div>
</template>


<script>
import plcMap from '@/json/plc.json'
import MlPageTable from '@/components/common/MlPageTable'
import ApeEditor from '@/components/ApeEditor'
import PriceGrids from '@/pages/publication/compoents/PriceGrids'

export default {
  name: 'AdjustPricePanel',
  components: { PriceGrids, ApeEditor, MlPageTable },
  props: {
    title: {
      type: String,
      default() {
        return '报价'
      }
    },
    //报价信息-Array
    quoteInfo: {
      type: [Object],
      default() {
        return {}
      }
    },
    editPrice: {
      type: Boolean,
      default() {
        return false
      }
    },
    platformType: {
      type: String,
      default() {
        return 'unknown'
      }
    },
    //显示重置按钮
    showReset: {
      type: Boolean,
      default() {
        return false
      }
    },
    //报价信息-品类场次报价
    showMultiPrice: {
      type: Boolean,
      default() {
        return true
      }
    },
    //报价信息-类目报价
    showSinglePrice: {
      type: Boolean,
      default() {
        return true
      }
    },
    //报价信息-专场权益
    showMixedPrice: {
      type: Boolean,
      default() {
        return true
      }
    }

  },
  data() {
    return {
      input_width: '100%',
      toolbar: 'undo redo paste',
      isRenderEditor: true
    }
  },
  computed: {
    quoteMixedStruct() {
      return plcMap.quoteInfo.mixed
    },
    quoteMultiCols() {
      if (plcMap && plcMap.quoteInfo && plcMap.quoteInfo.multi)
        return plcMap.quoteInfo.multi[this.platformType] || { 'label': '报价信息', 'cols': [] }

      return { 'label': '报价信息', 'cols': [] }
    }
  },
  methods: {
    show() {
      this.renderRichEditor()
      // if (this.showMultiPrice) {
      //   this.addMultiRow()
      // }
    },
    renderRichEditor() {
      this.isRenderEditor = false
      setTimeout(() => {
        this.isRenderEditor = true
      })
    },
    handleLSFCRichTxt(val, field) {
      if (this.quoteInfo.mixed) {
        if (this.quoteInfo.mixed[field]) {
          this.quoteInfo.mixed[field]['val'] = val
        }
      }
    },
    addFirstMultiRow() {
      if (this.quoteInfo && !this.quoteInfo.multi)
        this.$set(this.quoteInfo, 'multi', [])

      if (this.quoteInfo.multi.length === 0)
        this.quoteInfo.multi.push({})
    },
    addMultiRow() {
      if (this.quoteInfo && !this.quoteInfo.multi)
        this.$set(this.quoteInfo, 'multi', [])

      this.quoteInfo.multi.push({})
    },
    delMultiRow(index) {
      if (!this.quoteInfo.multi)
        this.$set(this.quoteInfo, 'multi', [])

      this.quoteInfo.multi.splice(index, 1)
    },
    handleReset() {
      this.$confirm('确定重置报价信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.$emit('handleReset')
      }).catch((e) => {
        console.log('err', e)
      })
    }
  }

}
</script>

<style scoped>
.opt-title {
  font-weight: bold;
  font-size: 16px;
}

.box-mixed {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.price-box {
  width: clamp(680px, 100%, 760px);
}

.price-mixed {
  border-radius: 5px;
  margin-bottom: 5px;

}

.price-title {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 1px;
  line-height: 24px;
  /*display: none;*/
}
</style>
